<template>
  <div class="Select" :style="guideBg">
    <div class="selection-box">
      <transition @afterLeave="disappearIntro()" leave-active-class="animate__animated animate__fadeOut animate__slow"
                  mode="out-in">
        <div  class="selection">
          <router-link to="/Home"><img :src="image_url + '/Guide/yes.gif'"/></router-link>
          <img :src="image_url + '/Guide/no.gif'" @click="disappearSelection()"/>
        </div>
      </transition>
    </div>
    <transition @afterLeave="appearMask()" leave-active-class="animate__animated animate__fadeOut animate__slow"
                mode="out-in">
      <div class="intro" :style="introBg">
        <p>强烈的好奇心，无畏的勇气，坚毅的内心，</p>
        <p>你是否愿意与我们一同探索、创造崭新的世界？</p>
      </div>
    </transition>
<!--    <transition enter-active-class="animate__animated animate__fadeIn animate__slow">-->
      <div v-if="selectionView" class="black-mask" v-bind:class="{'black-block-open':selectionShow}"></div>
<!--    </transition>-->
    <div class="end">Copyright @ www.3qlab.com,All Rights Reserved.<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19046295号-1</a></div>
  </div>
</template>

<script>
import 'animate.css';
import {image_url} from '../../../const';

export default {
  name: "guide_select",
  data() {
    return {
      selectionView: false,
      selectionShow: false,
      introShow: true,
      maskShow: false,
      image_url: image_url,
      guideBg: {
        background: 'url(' + image_url + '/Guide/guide_bg.png)',
        backgroundSize: 'cover'
      },
      introBg: {
        background: 'url(' + image_url + '/Guide/intro_bg.png)',
        backgroundSize: 'cover'
      }
    }
  },
  methods: {
    disappearSelection() {
      this.selectionView = true
      setTimeout(() => {
        this.selectionShow = true
      },100)
    },
    disappearIntro() {
      this.introShow = false
    },
    appearMask() {
      this.maskShow = true
    }
  }
}
</script>

<style scoped lang="scss">


.Select {
  width: 100%;
  margin: 0 auto;
  height: 873px;
  position: relative;
  // background: url(/images/Guide/guide_bg.png);
  // background-size: cover;
  .end{
    position: absolute;
    color: #A3A3A3;
    margin-top: 15px;
    bottom: 40px;
    left: 0;
    text-align: center;
    width: 100%;
    a{
      text-decoration: none;
      color: #A3A3A3;
      outline: none;
    }
  }
}

.selection {
  width: 60%;
  padding-top: 240px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  img {
    height: 250px;
    cursor: pointer;
  }
}

.selection-box {
  width: 100%;
  height: 491px;
  margin: 0 auto;
}

.intro {
  // background: url(/images/Guide/intro_bg.png);
  // background-size: cover;
  width: 500px;
  height: 70px;
  margin: 70px auto 0 auto;
  text-align: center;

  p {
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
    margin: 10px
  }
}

.black-mask {
  position:fixed;
  top: 0;
  height: 100%;
  background: black;
  width: 100%;
  opacity: 0;
  transition: 2s all;
}

.black-block-open {
  opacity: 1;
}
</style>