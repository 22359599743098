<template>
  <div class="guide">
    <Header :isMain="false"></Header>
    <Select></Select>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Select from "./com/select";

export default {
  data() {
    return {
      
    };
  },
  components: {
    Header,
    Select,
  },
  mounted() {

  },
  methods: {
    
  }

}
</script>

<style scoped lang="scss">

.guide {
  background-color: #000;
  width: 100%;
  height: 100%;
}


</style>